import React from 'react';
//@ts-ignore
import * as styles from './CloseButton.module.css';

interface Props {
    visualStyle?: 'light' | 'dark' | 'orange';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
}

const CloseButton: React.FC<Props> = ({ visualStyle = 'light', onClick }) => {
    const colors = {
        light: '#fff',
        dark: '#000',
        orange: '#f06623',
    };

    return (
        <button className={styles.closeButton} onClick={onClick} type="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.66 16.66">
                <defs />
                <g id="Layer_2" data-name="Layer 2">
                    <g id="artwork">
                        <path
                            fill="none"
                            stroke={colors[visualStyle]}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M.5.5l15.66 15.66M.5 16.16L16.16.5"
                        />
                    </g>
                </g>
            </svg>
        </button>
    );
};

export default CloseButton;
